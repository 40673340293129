
.newLine{
    display: flex;
}
.showPicture{
    margin-top: 20px;
    padding: 20px;
    background: #F5F7F9;
    margin-left: 100px;
    .imgBox{
        padding-top: 20px;
        display: flex;
        .imgBox-item{
            width: 70px;
            height: 70px;
            /*overflow: hidden;*/
            border: 1px solid #E5E5E5;
            position: relative;
            margin-left: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            i{
                position: absolute;
                right: -5px;
                top: -5px;
                color: #d81e06;
                font-size: 12px;
                cursor: pointer;
            }
            img{
                max-height: 100%;
                max-width: 100%;
            }
        }
        .imgBox-item:nth-child(1){
            margin-left: 0;
        }
    }
    .showPictureUpload{
        .uploadBtn{
            margin-top: 20px;
            cursor: pointer;
            width: 71px;
            height: 71px;
            border: 1px solid #E5E5E5;
            background: #FFFFFF;
            border-radius: 2px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            span{
                color: #999999;
                font-size: 12px;
                font-weight: 500;
            }
            i{
                font-size: 18px;
                color: #BFBFBF;
            }
        }
        .hint{
            display: block;
            margin-top: 20px;
            color: #999999;
            font-size: 14px;
            font-weight: 500;
        }
    }
}
.storeGoodsAdd{
    /*padding: 20px 26px;*/
    background: #ffffff;
    height: 100%;
    & > ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .storeGoodsAdd-container {
        padding: 20px 26px;
    }
    .storeGoodsAdd-title{
        color: #333333;
        font-weight: bold;
        font-size: 16px;
    }
    .storeGoodsAdd-middle{
        .storeGoodsAdd-middle-span{
            padding-top: 18px;
            color: #333333;
            font-weight: bold;
            font-size: 14px;
            span:nth-child(1){
                width: 3px;
                height: 10px;
                background: #1AB163;
                display: inline-block;
            }
            span:nth-child(2){
                margin-left: 10px;
            }
            .storeGoodsAdd-form{
                margin-top: 20px;
            }
        }

    }
}
